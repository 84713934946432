import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Account } from "./context/Account";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import 'bootstrap/dist/css/bootstrap.min.css';



if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <BrowserRouter>
            <Account>
                <Routes>
                    <Route path="/*" element={ <App/> }/>
                </Routes>
            </Account>
        </BrowserRouter>
);