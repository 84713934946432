import React, { useState } from "react";
import {
    Button,
    Card,
    Carousel,
    Col,
    Container,
    Row,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import Config from '../../config/Config';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import purple_background from "../../assets/images/purple-background.png";
import PopupModal from "../../components/PopupModal";
import Swal from 'sweetalert2';


const Posts = ({ posts, getPosts, setPostId, setIsPostSelected, setTutors, setLoading }) => {
    const axiosPrivate = useAxiosPrivate();

    const [index, setIndex] = useState(0);

    // Start Pop up section
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const [selectedPostId, setSelectedPostId] = useState(null);


    const handleConfirmCancel = async (post_id) => {
        try {
            setLoading(true);
            const delete_post_url = `${Config.backendURL}${Config.postsEndpoint}/${post_id}`;
            await axiosPrivate.delete(delete_post_url);
            await getPosts();
        } catch (error) {
            if (process.env.NODE_ENV !== 'production') {
                console.error('Error deleting post ', error);
            }
            Swal.fire({
                title: 'Error!',
                text: 'Error deleting this post, please try again later.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        } finally {
            setLoading(false);
        }
    };
    const popupInformation = {
        title: 'Confirm Deletion',
        body: 'Are you sure you want to delete this post? Please note that any related tutor applications, if applicable, will also be permanently removed.',
        id: selectedPostId,
        btn_background: Config.purple_color
    }
    // End Pop up section


    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleSelectPost = async (post_id) => {
        // client selected a post to see all the tutors that applied to it
        setLoading(true);

        setPostId(post_id);

        // get the list of tutors that applied for this post and have a status of ACCEPTED (tutors accepted by the admin user)
        const get_tutors_by_post_id_url = `${Config.backendURL}${Config.tutorApplicationEndpoint}/posts/${post_id}?current_status=${Config.Status.TUTOR_APPLICATION.ACCEPTED}`;

        try {
            const response = await axiosPrivate.get(get_tutors_by_post_id_url);
            const tutorsData = [];
            // get each tutor details 
            await Promise.all(response.data.map(async (tutor_application) => {
                const get_tutor_user_url = `${Config.backendURL}${Config.usersEndpoint}/${tutor_application.tutor_username}`;

                const response = await axiosPrivate.get(get_tutor_user_url);
                const tutor = response.data 
                tutor.tutor_application = tutor_application
                tutorsData.push(tutor);
            }));

            // Split the tutors data into chunks of 3
            const chunkedTutors = [];
            for (let i = 0; i < tutorsData.length; i += 3) {
                chunkedTutors.push(tutorsData.slice(i, i + 3));
            }

            // Set the state with chunked tutors data
            setTutors(chunkedTutors);
            // change the boolean so that the page renders the tutor selection layout
            setIsPostSelected(true);
        }
        catch (error) {
            if (process.env.NODE_ENV !== 'production') {
                console.error(error);
            }
            Swal.fire({
                title: 'Error!',
                text: 'Error while selecting this post, please try again later.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDeletePost = async (post_id) => {
        setSelectedPostId(post_id);
        setShowModal(true);
    };

    return (
        <div className="ezy__header30-cards">
            <Container>
                <Row className="mt-5">
                    <Carousel
                        // activeIndex={index}
                        onSelect={handleSelect}
                        controls={true}
                        indicators={false}
                        slide={false}
                        interval={null}
                    >
                        {
                            posts.length === 0 ? (
                                <Carousel.Item>
                                    <Row className={`d-flex justify-content-center align-items-center`}>
                                        <Col xs={12} md={4}>
                                            <Card className="border-0 mt-4 mt-lg-0">
                                                <Card.Header className="position-relative p-0 border-0">
                                                    <img
                                                        className="card-img-top"
                                                        src={purple_background}
                                                        alt=""
                                                    />
                                                    <div className="ezy__header30-card-content">
                                                        <h4>No posts published yet.</h4>
                                                    </div>
                                                </Card.Header>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Carousel.Item>
                            ) :
                                (
                                    posts.map((postList, i) => (
                                        <Carousel.Item key={i}>
                                            <Row className={`d-flex justify-content-center align-items-center`}>
                                                {postList.map((post, i) => (
                                                    <Col xs={12} md={4} key={i}>
                                                        <Card className="border-0 mt-4 mt-lg-0">
                                                            <Card.Header className="position-relative p-0 border-0">
                                                                <img
                                                                    className="card-img-top"
                                                                    src={purple_background}
                                                                    alt=""
                                                                />
                                                                <div className="ezy__header30-card-content">
                                                                    <h4>Post ID: {post.post_id}</h4>
                                                                    <p>Course: {post.course_name}</p>
                                                                    <p>Start Date: {post.start_date}</p>
                                                                    <p>
                                                                        Current Status: {post.current_status}
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip>
                                                                                    <strong>New:</strong> This post is pending review by our team and will be posted to Instagram once approved.<br />
                                                                                    <strong>Posted:</strong> This post has been published on Instagram, and tutors can now apply.
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <i className="fas fa-solid fa-question info-icon"></i>
                                                                        </OverlayTrigger>
                                                                    </p>
                                                                    <Button
                                                                        variant=""
                                                                        className="ezy__header30-btn mt-3"
                                                                        onClick={() => handleSelectPost(post.post_id)}
                                                                    >
                                                                        Select Post
                                                                    </Button>
                                                                    <Button
                                                                        variant=""
                                                                        className="ezy__header30-btn delete-btn mt-3"
                                                                        onClick={() => handleDeletePost(post.post_id)}
                                                                    >
                                                                        Delete Post
                                                                    </Button>
                                                                </div>
                                                            </Card.Header>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Carousel.Item>
                                    ))
                                )
                        }
                    </Carousel>
                </Row>
            </Container>
            <PopupModal
                show={showModal}
                onHide={handleCloseModal}
                onConfirm={handleConfirmCancel}
                additionalInfo={popupInformation}
            />
        </div>
    );
};

export default Posts;