import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {

  const { getSession } = useAuth();

  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [currentSession, setCurrentSession] = useState(null);

  // get the current session 
  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await getSession();
        setCurrentSession(response);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    checkSession();
  }, []);

  if (isLoading) {
    // Render loading indicator
    return <div className="loading">Loading...</div>;
  }

  // render the component depending on user roles. 
  // if the user belongs to a group passed in allowedRoles props, then it is displayed
  let renderedComponent;
  const userSetupComplete = localStorage.getItem('userSetupComplete');

  // Check if the user has a valid session and setup is complete
  if (currentSession && userSetupComplete) {
    // Check if the user has an allowed role or the default "user" role
    const hasAllowedRole = currentSession?.idToken?.payload?.['cognito:groups']?.find(role => allowedRoles?.includes(role));

    if (hasAllowedRole || allowedRoles.includes("user")) {
      renderedComponent = <Outlet />;
    } else {
      // User is logged in but does not have permission
      renderedComponent = <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
  } else {
    // User is not logged in or setup is incomplete
    renderedComponent = <Navigate to="/login" state={{ from: location }} replace />;
  }

  return renderedComponent;
}

export default RequireAuth;