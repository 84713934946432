import React, { useState } from "react";
import {
    Button,
    Card,
    Carousel,
    Col,
    Container,
    Row,
} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import Config from '../../config/Config';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import purple_background from "../../assets/images/purple-background.png";
import '../../assets/css/cards-carousel.css';
import Swal from 'sweetalert2';


const Tutors = ({ tutors, post_id, setShowModal, tutorsLinks }) => {
    const axiosPrivate = useAxiosPrivate();

    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleSelectTutor = async (tutor, post_id) => {
        if (tutor) {
            // confirm tutor selection and submit it to the backend
            setLoading(true);
            // change the status of the post by adding the tutor username and making the status FULFILLED
            const update_post_url = `${Config.backendURL}${Config.postsEndpoint}/${post_id}`;

            const update_data = {
                "tutor_username": tutor.username,
                "current_status": Config.Status.POST.FULFILLED
            };
            try {
                await axiosPrivate.put(update_post_url, update_data);
                setShowModal(true);
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error(error);
                }
                Swal.fire({
                    title: 'Error!',
                    text: 'Error selecting tutor, please try again later.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" variant="dark" />
                    <p>Loading...</p>
                </div>
            ) :
                <div className="ezy__header30-cards">
                    <Container>
                        <Row className="mt-5">
                            <Carousel
                                activeIndex={index}
                                onSelect={handleSelect}
                                controls={true}
                                indicators={false}
                                slide={false}
                                interval={null}
                            >
                                {tutors.length === 0 ? (
                                    <Carousel.Item>
                                        <Row className="d-flex justify-content-center align-items-center">
                                            <Col xs={12} md={4}>
                                                <Card className="border-0 mt-4 mt-lg-0">
                                                    <Card.Header className="position-relative p-0 border-0">
                                                        <img
                                                            className="card-img-top"
                                                            src={purple_background}
                                                            alt=""
                                                        />
                                                        <div className="ezy__header30-card-content">
                                                            <h4>No tutors applied for this post yet.</h4>
                                                        </div>
                                                    </Card.Header>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Carousel.Item>
                                ) : (
                                    tutors.map((tutorsList, i) => (
                                        <Carousel.Item key={i}>
                                            <Row className="d-flex justify-content-center align-items-center">
                                                {tutorsList.map((tutor, j) => (
                                                    <Col xs={12} md={4} key={j}>
                                                        <Card className="border-0 mt-4 mt-lg-0">
                                                            <Card.Header className="position-relative p-0 border-0">
                                                                <img
                                                                    className="card-img-top"
                                                                    src={purple_background}
                                                                    alt=""
                                                                />
                                                                <div className="ezy__header30-card-content">
                                                                    <h4>Name: {tutor.given_name} {tutor.family_name}</h4>
                                                                    {tutorsLinks[tutor.username]?.resume && (
                                                                        <a href={tutorsLinks[tutor.username]?.resume} target="_blank" rel="noopener noreferrer">
                                                                            <u>Resume</u>
                                                                        </a>
                                                                    )}
                                                                    {tutorsLinks[tutor.username]?.cover_letter && (
                                                                        <a href={tutorsLinks[tutor.username]?.cover_letter} target="_blank" rel="noopener noreferrer">
                                                                            <u>Cover Letter</u>
                                                                        </a>
                                                                    )}
                                                                    {tutorsLinks[tutor.username]?.additional_file && (
                                                                        <a href={tutorsLinks[tutor.username]?.additional_file} target="_blank" rel="noopener noreferrer">
                                                                            <u>Additional File</u>
                                                                        </a>
                                                                    )}
                                                                    <Button
                                                                        variant=""
                                                                        className="ezy__header30-btn mt-3"
                                                                        onClick={() => handleSelectTutor(tutor, post_id)}
                                                                    >
                                                                        Select Tutor
                                                                    </Button>
                                                                </div>
                                                            </Card.Header>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Carousel.Item>
                                    ))
                                )}
                            </Carousel>
                        </Row>
                    </Container>
                </div>
            }
        </>
    );
};

export default Tutors;
