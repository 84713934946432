import configData from "./production_config.json";

class Config {
  constructor() {
    this.userPoolId = process.env.NODE_ENV === 'production'
      ? configData.COGNITO_POOL_ID
      : process.env.REACT_APP_COGNITO_POOL_ID;

    this.clientId = process.env.NODE_ENV === 'production'
      ? configData.COGNITO_CLIENT_ID
      : process.env.REACT_APP_COGNITO_CLIENT_ID;

    this.backendURL = process.env.NODE_ENV === 'production'
      ? configData.BACKEND_URL
      : process.env.REACT_APP_BACKEND_URL;

    const baseApi = "api";
    const adminBase = `${baseApi}/admin`;
    
    this.postsEndpoint = `${baseApi}/posts`; 
    this.usersEndpoint = `${baseApi}/users`; 
    this.tutorApplicationEndpoint = `${baseApi}/tutor_applications`; 
    this.s3Endpoint = `${baseApi}/s3`; 
    this.adminPostsEndpoint = `${adminBase}/posts`;
    this.adminUsersEndpoint = `${adminBase}/users`; 
    this.adminTutorApplicationsEndpoint = `${adminBase}/tutor_applications`;

    this.purple_color = "#70448c";
    this.green_color = "#1e4d63";

    this.Status = {
      POST: {
        NEW: 'NEW',
        POSTED: 'POSTED',
        FULFILLED: 'FULFILLED',
        SUCCEEDED: 'SUCCEEDED',
        FAILED: 'FAILED'
      },
      TUTOR_APPLICATION: {
        ALL: '',
        ACCEPTED: 'ACCEPTED',
        REJECTED: 'REJECTED'
      }
    };
  }
}

const configInstance = new Config();

export default configInstance;