import React, { useEffect, useState, useRef, useContext } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Config from '../../config/Config';
import PopupModal from "../../components/PopupModal";
import { useNavigate } from "react-router-dom";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import side_form from "../../assets/images/side-form-2.png";
import { CountryContext } from '../../context/Country';


const ApplyForPositionForm = ({ postIdOptions, setShowModal, errRef, errMsg, setErrMsg }) => {
  const axiosPrivate = useAxiosPrivate();

  const [post_id, setPostId] = useState('');
  const [postDetails, setPostDetails] = useState({ '': 'Please select a post to check its details.' })
  const [importantNotes, setImportantNotes] = useState('');
  const [resumeFile, setResumeFile] = useState(undefined);
  const [letterFile, setLetterFile] = useState(undefined);
  const [additionalFile, setAdditionalFile] = useState(undefined);
  const [submitted, setSubmitted] = useState(false);
  const [validated, setValidated] = useState(false);

  const [validResume, setValidResume] = useState(true);
  const [validCoverLetter, setValidCoverLetter] = useState(true);
  const [validAdditionalDocs, setValidAdditionalDocs] = useState(true);

  const [loading, setLoading] = useState(false);

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

  const checkValidFile = (file) => {
    if ((file && file.type !== "application/pdf")
      || (file && file.size > MAX_FILE_SIZE)) {
      return false;
    }
    return true;
  }

  // validate resume file type and size
  useEffect(() => {
    setValidResume(checkValidFile(resumeFile));
  }, [resumeFile])

  // validate cover letter file type and size
  useEffect(() => {
    setValidCoverLetter(checkValidFile(letterFile));
  }, [letterFile])

  // validate additional docs file type and size
  useEffect(() => {
    setValidAdditionalDocs(checkValidFile(additionalFile));
  }, [additionalFile])

  const handlePostSelection = async (event) => {
    // on post selection, get the details about the post so the user can see what post it is about
    setPostId(event.target.value);
    const get_post_by_id_url = `${Config.backendURL}${Config.postsEndpoint}/${event.target.value}`;

    try {
      const response = await axiosPrivate.get(get_post_by_id_url);
      const post_details = {
        "Course Name": response.data.course_name,
        "Number of Sessions": response.data.number_of_sessions,
        "Start Date": response.data.start_date,
        "Location": response.data.location,
        "Price": response.data.price,
        "Online": response.data.online_sessions ? 'Yes' : 'No'
      };
      setPostDetails(post_details);
    }
    catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      setErrMsg("Error getting post detials, please try again later.");
      errRef.current.focus();
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg('');
    setSubmitted(true);
    setLoading(true);

    if (!post_id || !resumeFile) {
      setErrMsg("Please fill in all required fields.");
      errRef.current.focus();
    } else {
      const create_tutor_application_url = `${Config.backendURL}${Config.tutorApplicationEndpoint}`;

      try {
        let uploaded_resume = true;
        let uploaded_cover_letter = !!letterFile;
        let uploaded_additional_file = !!additionalFile;

        // Create tutor application in the backend server
        const response = await axiosPrivate.post(create_tutor_application_url, {
          post_id,
          additional_info: importantNotes,
          uploaded_resume: uploaded_resume,
          uploaded_cover_letter: uploaded_cover_letter,
          uploaded_additional_file: uploaded_additional_file
        });

        const tutor_application_id = response.data.tutor_application_id;

        await upload_file_to_s3(`${tutor_application_id}/Resume/resume`, resumeFile);

        if (uploaded_cover_letter) {
          await upload_file_to_s3(`${tutor_application_id}/CoverLetter/letter`, letterFile);
        }
        if (uploaded_additional_file) {
          await upload_file_to_s3(`${tutor_application_id}/Additional/file`, additionalFile);
        };
        setLoading(false);
        setShowModal(true);
      }
      catch (err) {
        setLoading(false);
        if (err.response) {
          setErrMsg("Error Submitting Form. Reason: " + err.response.data['detail']);
          errRef.current.focus();
        } else {
          setErrMsg("Error Submitting Form. Reason: " + err);
          errRef.current.focus();
        }
      }
    }
  }

  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];
    setFile(file); // Set the selected file
  };

  async function upload_file_to_s3(file_name, file) {
    const upload_file_to_s3_url = `${Config.backendURL}${Config.s3Endpoint}/upload_url/${file_name}`;

    // get presigned url from backend server to upload file to s3
    const presigned_url = await axiosPrivate.get(upload_file_to_s3_url);
    // upload file to s3 using the presigned url
    await fetch(
      presigned_url?.data['url'], {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });
  }

  const PostDetailsBox = () => {
    return postDetails ? (
      <Alert variant="info" className="mt-3">
        <h5>Selected Post Details</h5>
        <ul className="mb-0">
          {Object.entries(postDetails).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {value}
            </li>
          ))}
        </ul>
      </Alert>
    ) : null;
  };


  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <p ref={errRef} className={errMsg ? "ezy__form-errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="dark" />
          <p>Loading...</p>
        </div>
      ) :
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="applyforposition-post" className="mb-2">Post ID</Form.Label>
              <Form.Select
                id="applyforposition-post"
                value={post_id}
                onChange={(event) => handlePostSelection(event)}
                isInvalid={submitted && !post_id}
              >
                <option value="">Select a position</option>
                {postIdOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <PostDetailsBox />
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="applyforposition-resume">Resume (PDF format)</Form.Label>
              <Form.Control
                type="file"
                id="applyforposition-resume"
                accept=".pdf"
                onChange={(event) => handleFileChange(event, setResumeFile)}
              />
              <p className={resumeFile && !validResume ? "ezy__form-instructions" : "ezy__form-offscreen"}>File must be a PDF and less than 10MB.</p>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="applyforposition-notes">Important Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Relevant notes for this post"
                id="applyforposition-notes"
                value={importantNotes}
                onChange={(event) => { setImportantNotes(event.target.value) }}
                isInvalid={submitted && !importantNotes}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="applyforposition-letter">Cover Letter (PDF format)</Form.Label>
              <Form.Control
                type="file"
                id="applyforposition-letter"
                accept=".pdf"
                onChange={(event) => handleFileChange(event, setLetterFile)}
              />
              <p className={letterFile && !validCoverLetter ? "ezy__form-instructions" : "ezy__form-offscreen"}>File must be a PDF and less than 10MB.</p>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="applyforposition-additional">Additional Documents (PDF format)</Form.Label>
              <Form.Control
                type="file"
                id="applyforposition-additional"
                accept=".pdf"
                onChange={(event) => handleFileChange(event, setAdditionalFile)}
              />
              <p className={additionalFile && !validAdditionalDocs ? "ezy__form-instructions" : "ezy__form-offscreen"}>File must be a PDF and less than 10MB.</p>
            </Form.Group>
          </Col>
        </Row>
      }
      <button
        variant=""
        type="submit"
        className="ezy__form-btn-submit w-100"
        disabled={!importantNotes.trim() || !resumeFile || !post_id.trim() || !validResume || !validCoverLetter || !validAdditionalDocs ? true : false}
      >
        Submit
      </button>
    </Form>
  )
}

const ApplyForPositionFormCard = ({ postIdOptions, setShowModal, errRef, errMsg, setErrMsg }) => {
  return (
    <div className="ezy__form-form-card">
      <div className="p-0">
        <h2 className="ezy__form-heading mb-4 mb-md-5">Tutoring Position</h2>
        <ApplyForPositionForm
          postIdOptions={postIdOptions}
          setShowModal={setShowModal}
          errRef={errRef}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
        />
      </div>
    </div>
  )
};


function ApplyForPosition() {
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');

  const [postIdOptions, setpostIdOptions] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { country } = useContext(CountryContext);


  useEffect(() => {
    // load all posts that have POSTED status
    const loadPosts = async () => {
      const get_posts_url = `${Config.backendURL}${Config.postsEndpoint}/posted?country=${country}`;
      try {
        const response = await axiosPrivate.get(get_posts_url);
        setpostIdOptions(
          prevOptions => [
            ...prevOptions,
            ...response.data.map(
              (data) => ({
                value: data.post_id.toString(),
                label: '#' + data.post_id.toString()
              }))
          ]);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }
        setErrMsg("Error while loading data, please try again later.");
        errRef.current.focus();
      }
    }
    loadPosts();
  }, [])

  /* Pop Up Section when form is successfully submitted */
  const nextSteps = [
    "We will check your submitted documents.",
    "We will filter the top 5 candidates for this request.",
    "The student will select the his favorite tutor.",
    "We will send you an instagram message if you're the selected candidate."
  ];
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
    navigate(`/tutors/home`);
  }

  const popupInformation = {
    title: 'Successful Submit',
    body: `Thank you for using our services! We will get back to you as soon as we process your information!🌟\n You can manage all your applications in the "My Application" section. \n Next Steps:\n` +
      nextSteps.map((step, index) => `${index + 1}. ${step}`).join('\n'),
    btn_background: Config.green_color
  };

  return (
    <section className="ezy__form light d-flex">
      <Container>
        <Row className="justify-content-between h-100">
          <Col lg={5}>
            <div
              className="ezy__form-bg-holder d-none d-lg-block h-100"
              style={{ backgroundImage: `url(${side_form})` }}
            />
          </Col>
          <Col lg={6} className="py-5">
            <Row className="align-items-center h-100">
              <Col xs={12}>
                <ApplyForPositionFormCard
                  postIdOptions={postIdOptions}
                  setShowModal={setShowModal}
                  errRef={errRef}
                  errMsg={errMsg}
                  setErrMsg={setErrMsg}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <PopupModal
        show={showModal}
        onHide={handleCloseModal}
        additionalInfo={popupInformation}
      />
    </section>
  )
}

export default ApplyForPosition;
