import { axiosPrivate } from "./axios";
import { useEffect } from "react";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
    // this hook is implemented to add interceptors to every request going to the backend. 
    const { currentSession, getSession } = useAuth();


    useEffect(() => {
        // this interceptor is executed before sending the request to the backend
        // it adds an Authorization header to the request with the accessToken of the current logged in user.
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${currentSession?.accessToken?.jwtToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        // this interceptor is executed after receiving the request from the backend
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                // resend the request if 403 unauthorized occured with a new accessToken generated from the refreshToken 
                // the new accessToken is provided by requesting a new session (calling getSession())
                if (error?.response?.status === 403 && !prevRequest.sent) {
                    // making sure to only send the request one more time with prevRequest.sent
                    prevRequest.sent = true;
                    try {
                        const new_session = await getSession()
                        prevRequest.headers['Authorization'] = `Bearer ${new_session?.accessToken?.jwtToken}`;
                        return axiosPrivate(prevRequest);
                    } catch (error) {
                        if (process.env.NODE_ENV !== 'production') {
                            console.error(error);
                            alert(error);
                        }
                    }
                }
                return Promise.reject(error);
            }
        )

        return () => {
            // so the interceptors are not piled
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }

    }, [currentSession])

    return axiosPrivate;

}
export default useAxiosPrivate;