import React from "react";

const Alert = ({ message, type = "danger" }) => {
  return (
    <div className={`alert alert-${type} alert-dismissible fade show`} role="alert">
      {message}
    </div>
  );
};

export default Alert;
