import React from 'react';

function TableHeader(props) {
  const { columns, handleSort } = props;

  return (
    <tr>
      {columns.map((column) => (
        <th key={column} onClick={() => handleSort(column)}>
          {column.toUpperCase()}
        </th>
      ))}
      {(props.isPostTable || props.isTutorTable) ? (
        <>
          <th>ACTIONS</th>
          {props.isTutorTable && (
            <th>DETAILS</th>
          )}
        </>
      ) : null}
    </tr>
  );
}

export default TableHeader;