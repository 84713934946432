import React, { Fragment, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Col, Container, Form, Row } from "react-bootstrap";
import '../assets/css/image-form-overlapping.css';
import { CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from "./UserPool";
import useCountdown from '../hooks/useCountdown';
import side_form from "../assets/images/side-form-1.png";


const getCognitoUser = (email) => {
    // get the cognito user object by username and pool
    return new CognitoUser({
        Username: email,
        Pool: UserPool,
    });
};

const ResendCodeButton = ({ setErrMsg, props }) => {
	const errRef = useRef();

    const { secondsLeft, start } = useCountdown();

    const resendVerificationCode = () => {
        // resend confirmation code to the user's email another time
        const user = getCognitoUser(props.email);

        user.resendConfirmationCode((err, result) => {
            if (err) {
                setErrMsg(err.message);
                errRef.current.focus();
            } else {
                start(60);
            }
        }
        )
    };

    return (
        <Fragment>
            <button
                variant=""
                className="ezy__overlapping_form-btn-submit w-100"
                disabled={secondsLeft > 0}
                onClick={resendVerificationCode}>
                Resend Code {secondsLeft > 0 && ` (${secondsLeft})`}
            </button>
        </Fragment>)
};

const CodeVerificationForm = ({ errMsg, setErrMsg, props }) => {
    const [validated, setValidated] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [verificationCode, setVerificationCode] = useState('');

    const errRef = useRef();

    let navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true); // Mark that the form has been submitted
        const user = getCognitoUser(props.email);

        user.confirmRegistration(verificationCode, true, (err, data) => {
            if (err) {
                setErrMsg("Couldn't verify account.");
                errRef.current.focus();
            } else {
                if (props.page === "Register") {
                    // if the code verification process is being triggered after directly 
                    // signing up then redirect to login page
                    navigate('/login');
                } else if (props.page === "Login") {
                    // if the user did not verify his email directly after sign up and tries to login with it
                    // he will end up on this code verification step again. 
                    // the onValueChange function, calls a callback function in Login component telling it that 
                    // the verification of the user was successful, and login form will be displayed again
                    props.onValueChange(false);
                }
            }
        });
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <p ref={errRef} className={errMsg ? "ezy__overlapping_form-errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

            <Form.Group className="mb-4 mt-2">
                <Form.Label>Verification code sent to your email address</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Verification Code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    isInvalid={submitted && !verificationCode} />
            </Form.Group>

            <button
                variant=""
                type="submit"
                className="ezy__overlapping_form-btn-submit w-100"
            >
                Verify
            </button>
        </Form>
    );
}

const CodeVerificationFormCard = ({ errMsg, setErrMsg, props }) => {
    return (
        <div className="ezy__overlapping_form-form-card">
            <div className="p-md-5">
                <h2 className="ezy__overlapping_form-heading">Email Verification</h2>

                <CodeVerificationForm
                    errMsg={errMsg}
                    setErrMsg={setErrMsg}
                    props={props}
                />

                <div className="position-relative ezy__overlapping_form-or-separator">
                    <hr className="my-4 my-md-5" />
                    <span className="px-2">Or</span>
                </div>

                <ResendCodeButton
                    setErrMsg={setErrMsg}
                    props={props}
                />
            </div>
        </div>
    )
};

const CodeVerification = (props) => {
    const [errMsg, setErrMsg] = useState('');

    return (
        <section className="ezy__overlapping_form light d-flex">
            <Container>
                <Row className="justify-content-between h-100">
                    <Col md={4} lg={6}>
                        <div
                            className="ezy__overlapping_form-bg-holder d-none d-md-block h-100"
                            style={{ backgroundImage: `url(${side_form})` }}
                        />
                    </Col>
                    <Col md={8} lg={6} className="py-5">
                        <Row className="align-items-center h-100 px-xl-5">
                            <Col xs={12}>
                                <CodeVerificationFormCard
                                    errMsg={errMsg}
                                    setErrMsg={setErrMsg}
                                    props={props}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default CodeVerification;