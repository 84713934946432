import React from 'react'
import { useState, useEffect } from "react";
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import AdditionalInformationWindow from './AdditionalInformationWindow';
import Config from '../config/Config';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';


function Table(props) {
  const [data, setData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsToShow, setItemsToShow] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);


  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getData = async () => {
      try {
        const response = await axiosPrivate.get(props.url, {
          signal: controller.signal
        });
        if (props.isPostTable) {
          // Iterate through each post in the response data
          response.data.forEach(post => {
            // Retrieve client additional information, if available
            const clientAdditionalInfo = post["client_additional_info"];
            if (clientAdditionalInfo) {
              // Add client email to the post if client additional info is not null
              post["client_email"] = clientAdditionalInfo["email"];
            }

            // Retrieve tutor additional information, if available
            const tutorAdditionalInfo = post["tutor_additional_info"];
            if (tutorAdditionalInfo) {
              // Add tutor email to the post if tutor additional info is not null
              post["tutor_email"] = tutorAdditionalInfo["email"];
            }
          });
        }
        isMounted && setData(response.data);
        setLoading(false);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }
        Swal.fire({
          title: 'Error!',
          text: 'Error getting table info, please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
      });
      }
    }
    getData();
    return () => {
      isMounted = false;
      controller.abort();
    }

  }, [])


  function handleSort(column) {
    if (column === sortColumn) {
      // If the same column is clicked again, reverse the sort direction
      setData([...data].reverse());
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Sort the data by the selected column
      const sortedData = [...data].sort((a, b) => {
        if (a[column] < b[column]) {
          return sortDirection === "asc" ? -1 : 1;
        }
        if (a[column] > b[column]) {
          return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
      setData(sortedData);
      setSortColumn(column);
      setSortDirection("asc");
      setCurrentPage(1); // Reset the current page to 1
    }
  }

  function getTotalPages() {
    return Math.ceil(data.length / pageSize);
  }

  function handleButtonClick(button) {
    if (button === "prev") {
      setCurrentPage(currentPage - 1);
    } else if (button === "next") {
      setCurrentPage(currentPage + 1);
    }
  }

  useEffect(() => {
    const lowerCasedSearchTerm = searchTerm.toLowerCase();
    const filteredItems = data.filter((item) => {
      return (
        !searchTerm ||
        props.tableHeaders.some((field) => item[field]?.toString().toLowerCase()?.includes(lowerCasedSearchTerm))
      );
    });
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const slicedItems = filteredItems.slice(startIndex, endIndex);
    setItemsToShow(slicedItems);
  }, [searchTerm, data, currentPage, pageSize]);

  // to view extra details about one row 
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // display the additional view window
  const openModal = async (item) => {
    const get_tutor_user_url = `${Config.backendURL}${Config.adminUsersEndpoint}/${item.tutor_username}`;
    try {
      const response = await axiosPrivate.get(get_tutor_user_url);
      // add tutor_application so that it can be used to retrieve files from S3 bucket
      response.data.tutor_application = item;
      setSelectedItem(response.data);
      setShowModal(true);

    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      Swal.fire({
        title: 'Error!',
        text: 'Error getting additional info, please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
    });
    }
  };

  // close the additional view window
  const closeModal = () => {
    setSelectedItem(null);
    setShowModal(false);
  };


  return (
    <div className="table-container">
      <div className="table-bar">
        <h2>{props.tableName}</h2>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className='table-and-details'>
        <table>
          <thead>
            <TableHeader
              columns={props.tableHeaders}
              handleSort={handleSort}
              isPostTable={props.isPostTable}
              isTutorTable={props.isTutorTable}
              isStudentTable={props.isStudentTable}
            />
          </thead>
          {loading
            ?
            <div className="spinner-container">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
            :
            <TableRow itemsToShow={itemsToShow}
              headers={props.tableHeaders}
              isPostTable={props.isPostTable}
              isTutorTable={props.isTutorTable}
              isStudentTable={props.isStudentTable}
              onRowClick={openModal} />
          }
          <tfoot className='table-footer'>
            <tr>
              <td colSpan="20">
                <button
                  disabled={currentPage === 1}
                  onClick={() => handleButtonClick("prev")}
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {getTotalPages()}
                </span>
                <button
                  disabled={currentPage === getTotalPages()}
                  onClick={() => handleButtonClick("next")}
                >
                  Next
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
        {showModal && (
          <AdditionalInformationWindow isOpen={showModal} closeModal={closeModal} item={selectedItem} />
        )}
      </div>
    </div>
  )
}

export default Table