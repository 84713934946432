// src/components/PopupModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const PopupModal = ({ show, onHide, onConfirm, additionalInfo }) => {
    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm(additionalInfo.id);
        }
        onHide(); // Close the modal after confirming
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{additionalInfo.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {additionalInfo.body.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ background: additionalInfo.btn_background }} variant="secondary" onClick={onHide}>
                    Close
                </Button>
                {onConfirm && (
                    <Button style={{ background: '#b22222c9' }} variant="danger" onClick={handleConfirm}>
                        Confirm
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};


export default PopupModal;
