import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Config from '../config/Config';
import Swal from 'sweetalert2';



function TableRow({ itemsToShow, headers, isPostTable, isTutorTable, isStudentTable, onRowClick }) {
  const [action, setAction] = useState('');
  const axiosPrivate = useAxiosPrivate()

  const post_action_options = [
    { value: Config.Status.POST.POSTED, label: "POSTED" },
    { value: Config.Status.POST.SUCCEEDED, label: "SUCCEEDED" },
    { value: Config.Status.POST.FAILED, label: "FAILED" }
  ]

  const tutor_application_action_options = [
    { value: Config.Status.TUTOR_APPLICATION.ACCEPTED, label: "ACCEPTED" },
    { value: Config.Status.TUTOR_APPLICATION.REJECTED, label: "REJECTED" }

  ]
  const handleActionClick = async (event, item) => {

    const update_post_url = `${Config.backendURL}${Config.postsEndpoint}/${item.post_id}`;
    try {
      await axiosPrivate.put(update_post_url, {"current_status": event.target.value});
      window.location.reload();
    }catch (error){
      if (process.env.NODE_ENV !== 'production') {
        console.error(error)
      }
      Swal.fire({
        title: 'Error!',
        text: 'Error updating status, please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
    });
    }
  }

  const handleTutorActionClick = async (event, item) => {

    const update_tutor_application_url = `${Config.backendURL}${Config.adminTutorApplicationsEndpoint}/${item.tutor_application_id}?current_status=${event.target.value}`;

    try{
      await axiosPrivate.put(update_tutor_application_url)
      window.location.reload();
    }catch (error){
      if (process.env.NODE_ENV !== 'production') {
        console.error(error)
      }
      Swal.fire({
        title: 'Error!',
        text: 'Error updating status, please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
    });
    }
  }



  return (
    <tbody>
      {itemsToShow.map((item, index) => (
        <tr key={index}>
          {headers.map((header) => (
            <td key={header}>
              {typeof item[header] === 'boolean' ? (item[header] ? 'Yes' : 'No') : item[header]}
            </td>
          ))}

          {isPostTable ?
            (
              <td>
                <select className='table-select' name={"actions"} id={"actions"} value={action} onChange={(event) => { handleActionClick(event, item) }}>
                  <option value="">Change Status</option>
                  {post_action_options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
            ) : isTutorTable ?
              (
                <>
                  <td>
                    <select className='table-select' name={"actions"} id={"actions"} value={action} onChange={(event) => { handleTutorActionClick(event, item) }}>
                      <option value="">Change Status</option>
                      {tutor_application_action_options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <button className='table-details-button' onClick={() => onRowClick(item)}>View</button>
                  </td>
                </>
              ) : null}
        </tr>
      ))}
    </tbody>
  );
}

export default TableRow;
