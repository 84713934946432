import React from 'react'

function Missing() {
  return (
    <div className='missing'>
    <h2>Oops! Page Missing.</h2>
    <p>
      The page you are looking for is missing.
    </p>
  </div>
  )
}

export default Missing