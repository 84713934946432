import Config from '../config/Config';
import Swal from 'sweetalert2';

export const get_tutor_description = async (tutor, axiosPrivate) => {
    // Gets the links to the files uploaded by the tutor applying for the post.
    // Resume is required, the rest of the files are first checked for existence before fetching their links.

    let tutor_file_links = {};
    // Construct the base download URL for the tutor's files
    const base_download_url = `${Config.backendURL}${Config.s3Endpoint}/download_url/${tutor.username}`;

    try {
        // Fetch and store the resume link (required file)
        const resume_response = await axiosPrivate.get(`${base_download_url}/${tutor.tutor_application.tutor_application_id}/Resume/resume`);
        tutor_file_links["resume"] = resume_response.data['url'];  // Use the response URL

        // Check if cover letter exists, fetch and store the link if it does
        if (tutor.tutor_application.uploaded_cover_letter) {
            const letter_response = await axiosPrivate.get(`${base_download_url}/${tutor.tutor_application.tutor_application_id}/CoverLetter/letter`);
            tutor_file_links["cover_letter"] = letter_response.data['url'];
        }

        // Check if an additional file exists, fetch and store the link if it does
        if (tutor.tutor_application.uploaded_additional_file) {
            const file_response = await axiosPrivate.get(`${base_download_url}/${tutor.tutor_application.tutor_application_id}/Additional/file`);
            tutor_file_links["additional_file"] = file_response.data['url'];
        }

        // Return the object containing the file links
        return tutor_file_links;
    } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
            console.error("Error fetching tutor file links:", err);
        }
        Swal.fire({
            title: 'Error!',
            text: 'Error getting additional tutor details, please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
        });
        return;
    }
}
