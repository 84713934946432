// src/context/CountryContext.js
import React, { createContext, useState } from 'react';

const CountryContext = createContext();

const CountryProvider = ({ children }) => {
    const [country, setCountry] = useState('LEB'); // Default country as Lebanon

    return (
        <CountryContext.Provider value={{ country, setCountry }}>
            {children}
        </CountryContext.Provider>
    );
};

export {CountryContext, CountryProvider};
