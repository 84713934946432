import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Config from '../../config/Config';
import { get_tutor_description } from '../../utils/TutorService';
import '../../assets/css/cards-carousel.css';
import Posts from "./Posts";
import Tutors from "./Tutors";
import PopupModal from "../../components/PopupModal";
import { useNavigate } from "react-router-dom";
import select_tutor_bg from "../../assets/images/select-tutor.png";
import Swal from 'sweetalert2';


const SelectTutor = () => {
    const [posts, setPosts] = useState([]);
    const [post_id, setPostId] = useState('');
    const [isPostSelected, setIsPostSelected] = useState(false);
    const [tutors, setTutors] = useState([]);
    const [tutorsLinks, setTutorsLinks] = useState([])
    const [loading, setLoading] = useState(false);
    
    const axiosPrivate = useAxiosPrivate();

    const get_posts = async () => {
        setLoading(true);
        const get_post_by_client_username_url = `${Config.backendURL}${Config.postsEndpoint}/client_posts?current_status=${Config.Status.POST.POSTED}&current_status=${Config.Status.POST.NEW}`;
        try {
            const response = await axiosPrivate.get(get_post_by_client_username_url);
            const chunkedPosts = [];
            for (let i = 0; i < response.data.length; i += 3) {
                chunkedPosts.push(response.data.slice(i, i + 3));
            }
            setPosts(chunkedPosts);
        }
        catch (error) {
            if (process.env.NODE_ENV !== 'production') {
                console.error(error);
            }
            Swal.fire({
                title: 'Error!',
                text: 'Error getting your posts, please try again later.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        // load all posted posts on page mount
        get_posts();
    }, [])

    useEffect(() => {
        const get_tutors_links = async () => {
            // variable that will contain the files urls of the tutor.
            let tutors_links = {};

            for (let tutorsChunk of tutors) {
                for (let tutor of tutorsChunk) {
                    tutors_links[tutor.username] = await get_tutor_description(tutor, axiosPrivate);
                }
            }
            setTutorsLinks(tutors_links)
        }
        get_tutors_links();
    }, [tutors]);

    /* Pop Up Section when tutor is successfully selected */
    const nextSteps = [
        "Congratulations on selecting a tutor!",
        "We will message you the details of the tutor in the coming hours."
    ]

    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleCloseModal = () => {
        setShowModal(false);
        navigate(`/students/home`);
    }

    const popupInformation = {
        title: 'Successful Submit',
        body: `Thank you for using our services! We will get back to you as soon as we process your information!🌟\n Next Steps:\n` +
            nextSteps.map((step, index) => `${index + 1}. ${step}`).join('\n'),
        btn_background: Config.purple_color
    };

    switch (isPostSelected) {
        // The user has still not selected a post to view the tutors that applied to it
        case false:
            return (
                <section className="ezy__header30 light">
                    <div
                        className="ezy__header30-topbar"
                        style={{
                            backgroundImage: `url(${select_tutor_bg})`,
                        }}
                    >
                        <div className="ezy__header30-overlay" />
                        <Container>
                            <Row className="justify-content-center text-center">
                                <Col xs={12}>
                                    <h1 className="ezy__header30-heading mb-4">
                                        Manage your Posts
                                    </h1>
                                    <Row className="justify-content-center">
                                        <Col xs={12} lg={5} className="text-center">
                                            <p className="ezy__header30-sub-heading">
                                                Select an active post to connect with the right tutor and take the next step in your learning journey.
                                                Active posts are those currently featured on our Instagram page.
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="dark" />
                            <p>Loading...</p>
                        </div>
                    ) :
                        <Posts
                            posts={posts}
                            getPosts={get_posts}
                            setPostId={setPostId}
                            setIsPostSelected={setIsPostSelected}
                            tutors={tutors}
                            setTutors={setTutors}
                            setTutorsLinks={setTutorsLinks}
                            setLoading={setLoading}
                        />
                    }
                </section>
            );
        default:
            // the user has selected a post and here the tutor info is being displayed to him
            return (
                <section className="ezy__header30 light">
                    <div
                        className="ezy__header30-topbar"
                        style={{
                            backgroundImage: `url(${select_tutor_bg})`,
                        }}
                    >
                        <div className="ezy__header30-overlay" />
                        <Container>
                            <Row className="justify-content-center text-center">
                                <Col xs={12}>
                                    <h1 className="ezy__header30-heading mb-4">
                                        Available Tutors
                                    </h1>
                                    <Row className="justify-content-center">
                                        <Col xs={12} lg={5} className="text-center">
                                            <p className="ezy__header30-sub-heading">
                                                Browse through our list of qualified tutors and select the one who best meets your needs. You can review their resumes, academic records, cover letters, and any other documents they've provided to help you make the right choice.
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="dark" />
                            <p>Loading...</p>
                        </div>
                    ) :
                        <Tutors
                            tutors={tutors}
                            post_id={post_id}
                            setShowModal={setShowModal}
                            tutorsLinks={tutorsLinks}
                        />
                    }
                    <PopupModal
                        show={showModal}
                        onHide={handleCloseModal}
                        additionalInfo={popupInformation}
                    />
                </section>
            )
    }
};

export default SelectTutor;
