import React from 'react'

function Unauthorized() {
  return (
    <div className='unauthorized'>
      <h2>Unauthorized Access!</h2>
      <p>
        Sorry, you are not authorized to access this page.
      </p>
    </div>
  )
}

export default Unauthorized