import React, { useRef, useState, useEffect } from "react";
import Pool from '../../components/UserPool'
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';


import { Col, Container, Form, Row } from "react-bootstrap";
import '../../assets/css/image-form-overlapping.css';
import side_form from "../../assets/images/side-form-1.png";



const getUser = (email) => {
    return new CognitoUser({
        Username: email.toLowerCase(),
        Pool
    });
};

const EmailForm = ({ email, setEmail, stage, setStage, errRef, errMsg, setErrMsg }) => {
    const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const [validated, setValidated] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [validEmail, setValidEmail] = useState(false);

    useEffect(() => {
        setErrMsg('');
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true); // Mark that the form has been submitted

        const v1 = EMAIL_REGEX.test(email);
        if (!v1) {
            setErrMsg("Invalid Email");
            return;
        }
        // sends a new code to the user email address, to verify that it is him triggering the 
        // password change
        getUser(email).forgotPassword({
            onFailure: err => {
                setErrMsg(err);
            },
            inputVerificationCode: data => {
                // initiate stage two: code insertion, password change & password comfirmation
                setStage(2);
            }
        });
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <p ref={errRef} className={errMsg ? "ezy__overlapping_form-errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

            <Form.Group className="mb-4 mt-2">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" placeholder="Your Email Address" value={email} onChange={(e) => setEmail(e.target.value)} isInvalid={submitted && !email} />
            </Form.Group>
            <button
                variant=""
                type="submit"
                className="ezy__overlapping_form-btn-submit w-100"
                disabled={!validEmail ? true : false}
            >
                Send Code
            </button>
        </Form>
    );
};

const EmailFormCard = ({ email, setEmail, stage, setStage, errRef, errMsg, setErrMsg }) => {
    return (
        <div className="ezy__overlapping_form-form-card">
            <div className="p-md-5">
                <h2 className="ezy__overlapping_form-heading ">Reset Password</h2>
                <EmailForm
                    email={email}
                    setEmail={setEmail}
                    stage={stage}
                    setStage={setStage}
                    errRef={errRef}
                    errMsg={errMsg}
                    setErrMsg={setErrMsg}
                />
            </div>
        </div>
    )
};



const NewPasswordForm = ({ email, stage, setStage, errRef, errMsg, setErrMsg }) => {
    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,24}$/;


    const [validated, setValidated] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [code, setCode] = useState('');

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);


    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])


    let navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true); // Mark that the form has been submitted
        const v2 = PWD_REGEX.test(pwd);
        if (!v2) {
            setErrMsg("Invalid Password");
            return;
        }
        //extra verification process, if bypassed html
        if (pwd !== matchPwd) {
            return;
        }
        // change user's password by submitting the new password and code sent to his email
        getUser(email).confirmPassword(code, pwd, {
            onSuccess: data => {
                navigate('/login');
            },
            onFailure: err => {
                setErrMsg(err.message);
            }
        });
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <p ref={errRef} className={errMsg ? "ezy__overlapping_form-errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

            <Form.Group className="mb-4 mt-2">
                <Form.Label>Verification Code</Form.Label>
                <Form.Control type="text" placeholder="Your Verification Code" value={code} onChange={e => setCode(e.target.value)} isInvalid={submitted && !code} />
            </Form.Group>
            <Form.Group className="mb-4 mt-2">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter New Password" value={pwd} onChange={(e) => setPwd(e.target.value)} isInvalid={submitted && !pwd} />
                <p className={pwd.trim() && !validPwd ? "ezy__overlapping_form-instructions" : "ezy__overlapping_form-offscreen"}> 8 to 24 characters. Must include uppercase and lowercase letters, a number and symbol.</p>
            </Form.Group>
            <Form.Group className="mb-4 mt-2">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" placeholder="Confirm Your Password" value={matchPwd} onChange={(e) => setMatchPwd(e.target.value)} isInvalid={submitted && !matchPwd} />
                <p className={!validMatch ? "ezy__overlapping_form-instructions" : "ezy__overlapping_form-offscreen"}>Password does not match.</p>
            </Form.Group>
            <button
                variant=""
                type="submit"
                className="ezy__overlapping_form-btn-submit w-100"
                disabled={!validPwd || !validMatch || !code ? true : false}
            >
                Send Code
            </button>
        </Form>
    );
};


const NewPasswordFormCard = ({ email, stage, setStage, errRef, errMsg, setErrMsg }) => {
    return (
        <div className="ezy__overlapping_form-form-card">
            <div className="p-md-5">
                <h2 className="ezy__overlapping_form-heading ">Reset Password</h2>
                <NewPasswordForm
                    email={email}
                    stage={stage}
                    setStage={setStage}
                    errRef={errRef}
                    errMsg={errMsg}
                    setErrMsg={setErrMsg}
                />
            </div>
        </div>
    )
};

const ForgetPassword = () => {
    // 1 = email stage, 2 = code stage
    const [stage, setStage] = useState(1);
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');

    const [email, setEmail] = useState('');


    return (
        <>
            {
                stage === 1 && (
                    <section className="ezy__overlapping_form light d-flex">
                        <Container>
                            <Row className="justify-content-between h-100">
                                <Col md={4} lg={6}>
                                    <div
                                        className="ezy__overlapping_form-bg-holder d-none d-md-block h-100"
                                        style={{ backgroundImage: `url(${side_form})` }}
                                    />
                                </Col>
                                <Col md={8} lg={6} className="py-5">
                                    <Row className="align-items-center h-100 px-xl-5">
                                        <Col xs={12}>
                                            <EmailFormCard
                                                email={email}
                                                setEmail={setEmail}
                                                stage={stage}
                                                setStage={setStage}
                                                errRef={errRef}
                                                errMsg={errMsg}
                                                setErrMsg={setErrMsg}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                )
            }
            {
                stage === 2 && (
                    <section className="ezy__overlapping_form light d-flex">
                        <Container>
                            <Row className="justify-content-between h-100">
                                <Col md={4} lg={6}>
                                    <div
                                        className="ezy__overlapping_form-bg-holder d-none d-md-block h-100"
                                        style={{
                                            backgroundImage:
                                                "url(https://cdn.easyfrontend.com/pictures/sign-in-up/sign2.jpg)",
                                        }}
                                    />
                                </Col>
                                <Col md={8} lg={6} className="py-5">
                                    <Row className="align-items-center h-100 px-xl-5">
                                        <Col xs={12}>
                                            <NewPasswordFormCard
                                                email={email}
                                                stage={stage}
                                                setStage={setStage}
                                                errRef={errRef}
                                                errMsg={errMsg}
                                                setErrMsg={setErrMsg}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                )
            }
        </>

    )
};

export default ForgetPassword;