import React, { useEffect, useState } from 'react';
import { get_tutor_description } from '../utils/TutorService';
import useAxiosPrivate from '../hooks/useAxiosPrivate';



function AdditionalInformationWindow({ isOpen, closeModal, item }) {

  const [tutorLinks, setTutorLinks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (isOpen && item) {
      const fetchTutorDescription = async () => {
        try {
          setLoading(true);
          const description = await get_tutor_description(item, axiosPrivate);
          setTutorLinks(description);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };

      fetchTutorDescription();
    }
  }, [isOpen, item, axiosPrivate]);

  if (!isOpen) return null;

  return (
    <div className='additional-tutor-info-modal-overlay'>
      <div className="additional-info">
        <h2>Tutor Details</h2>
        {loading ? (
          <p>Loading additional information...</p>
        ) : error ? (
          <p>Error loading information: {error.message}</p>
        ) : (
          item && tutorLinks && (
            <>
              <p>Name: {item.given_name}</p>
              <p>Last Name: {item.family_name}</p>
              <p>Birthdate: {item.birthdate}</p>
              <p>Gender: {item.gender}</p>
              <p>Email: {item.email}</p>
              <p>Phone Number: {item.phone_number}</p>
              <p>Resume: <a href={tutorLinks.resume} target="_blank" rel="noopener noreferrer">View Resume</a></p>
              {tutorLinks.cover_letter && (
                <p>Cover Letter: <a href={tutorLinks.cover_letter} target="_blank" rel="noopener noreferrer">View Cover Letter</a></p>
              )}
              {tutorLinks.additional_file && (
                <p>Additional File: <a href={tutorLinks.additional_file} target="_blank" rel="noopener noreferrer">View Additional File</a></p>
              )}
            </>
          )
        )}
        <button onClick={closeModal}>Close</button>
      </div>
    </div>
  );
}

export default AdditionalInformationWindow;
